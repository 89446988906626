import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import BestRanking from "./BestRanking";
import data from "./MockData";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "best-ranking"
    }}>{`Best Ranking`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={BestRanking} mdxType="Props" />
    <h2 {...{
      "id": "api-basic-usage"
    }}>{`API Basic Usage`}</h2>
    <Playground __position={1} __code={'<BestRanking state=\"Texas\" year=\"2020\" collapsed={true} maxItems={10} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      BestRanking,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BestRanking state="Texas" year="2020" collapsed={true} maxItems={10} mdxType="BestRanking" />
    </Playground>
    <h2 {...{
      "id": "custom-data-basic-usage"
    }}>{`Custom Data Basic Usage`}</h2>
    <p>{`Structure defined for `}<strong parentName="p">{`data`}</strong>{` parameter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const data = [
  {
    company: 'Allstate',
    url: 'https://www.allstate.com/auto-insurance.aspx',
    score: '75',
    satisfaction: '3',
    claims: '2',
    affordability: '3',
    stability: 'A+'
  },
  {
    company: 'GEICO',
    url: 'https://www.geico.com/',
    score: '85',
    satisfaction: '3',
    claims: '3',
    affordability: '3',
    stability: 'A++'
  },
  {
    company: 'Progressive',
    score: '80',
    satisfaction: '2',
    claims: '3',
    affordability: '3',
    stability: 'A+'
  },
  {
    company: 'State Farm',
    url: 'https://www.statefarm.com/insurance/auto',
    score: '90',
    satisfaction: '3',
    claims: '3',
    affordability: '3',
    stability: 'A++'
  },
  {
    company: 'USAA',
    score: '100',
    satisfaction: '5',
    claims: '5',
    affordability: '5',
    stability: 'A++'
  }
];
`}</code></pre>
    <Playground __position={2} __code={'<BestRanking data={data} collapsed={true} maxItems={10} />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      BestRanking,
      data,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <BestRanking data={data} collapsed={true} maxItems={10} mdxType="BestRanking" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      